<!--
异常预警
-->
<template>
  <div class="mainBox">
    <div class="shipTitle">
      <div class="changTitle">
        <div
          class="changeItme"
          :class="changeIndex == 1 ? 'changeItmeActive' : ''"
          @click="changeIndex = 1"
        >
          异常预警
        </div>
      </div>
    </div>
    <!---->
    <div class="shipMain shipMainNoPad">
      <!-- table -->
      <div class="tableBox">
        <el-table
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          :row-class-name="tableRowClassName"
        >
          <el-table-column prop="content" label="工单内容" width="180">
          </el-table-column>
          <el-table-column prop="userID" label="客户编号" width="180">
          </el-table-column>
          <el-table-column prop="mobile" label="手机" width="180">
          </el-table-column>
          <el-table-column prop="telTime" label="来电时间" width="180">
          </el-table-column>
          <el-table-column prop="createUserId" label="创建人" width="180">
          </el-table-column>
          <el-table-column prop="address" label="地址" width="180">
          </el-table-column>
          <el-table-column prop="result" label="处理结果" width="180">
          </el-table-column>
          <el-table-column prop="result" label="工单状态" width="180">
            <template slot-scope="scope">
              <div
                class="statusBox statusBoxColor1"
                v-if="scope.row.status == 0"
              >
                待办
              </div>
              <div
                class="statusBox statusBoxColor2"
                v-if="scope.row.orderState == 1"
              >
                正在处理
              </div>
              <div
                class="statusBox statusBoxColor3"
                v-if="scope.row.orderState == 2"
              >
                完成
              </div>
              <div
                class="statusBox statusBoxColor2"
                v-if="scope.row.orderState == 3"
              >
                转交
              </div>
            </template>
          </el-table-column>
        </el-table>

        <div class="pagBox">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="sendData.pageIndex"
            :page-size="10"
            layout="total, prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetSecurityPager } from "../../api/homeGuardianship";
export default {
  components: {},
  data() {
    return {
      timeData: "",
      changeIndex: 1,
      loading: true,
      tableData: [],
      sendData: {
        pageIndex: 1,
        pageSize: 10,
      },
      total: 0,
    };
  },
  created() {
    this.getSecurityPager();
  },

  mounted: function () {},
  destroyed: function () {},
  computed: {},
  watch: {},
  methods: {
    tableRowClassName({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "double-row";
      }
      return "";
    },
    handleCurrentChange() {
      this.getSecurityPager();
    },
    getSecurityPager() {
      this.loading = true;
      GetSecurityPager(this.sendData).then((res) => {
        this.loading = false;
        let data = res.dataList;
        this.tableData = data;
        this.total = res.totalRecordCount;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.statusBox {
  min-width: 75px;
  min-height: 26px;
  border-radius: 13px 13px 13px 13px;
  line-height: 26px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  display: inline-block;
}
.statusBoxColor1 {
  border: 1px solid #fd500a;
  color: #fd500a;
}
.statusBoxColor2 {
  border: 1px solid #03ad82;
  color: #03ad82;
}
.statusBoxColor3 {
  border: 1px solid #ffc800;
  color: #ffc800;
}

.statusBoxColor1 {
  border: 1px solid #fd500a;
  color: #fd500a;
}
.statusBoxColor2 {
  border: 1px solid #03ad82;
  color: #03ad82;
}
.statusBoxColor3 {
  border: 1px solid #ffc800;
  color: #ffc800;
}
</style>
